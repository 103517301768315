import { useUserStore } from "@/stores/user.store";
import { useNotification } from "@kyvg/vue3-notification";
import axios from "axios";
import { defineStore, getActivePinia } from "pinia";
const notification = useNotification();
const baseUrl = process.env.VUE_APP_NODE_ENV === 'local' ? process.env.VUE_APP_API_URL : `${window.location.origin}`;
const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "api-token": process.env.VUE_APP_LIST_PROVIDER_API_TOKEN,
  },
};
const resetAllPiniaStores = () => {
  const activeStores = Object.keys(getActivePinia().state.value);

  activeStores.forEach((store) => {
    if (store != "isLoading") {
      getActivePinia()._s.get(store).$reset();
    }
  });
};
const permissions = {
  superadmin: [
    "list-manager-list",
    "acitivity-list",
    "acitivity-list-edit",
    "settings",
    "list-manager-add",
    "list-manager-import",
    "list-manager-delete",
    "list-manager-edit",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
    "settings-add-user",
    "settings-add-monitoringUser",
    "export-user-list"
  ],
  monitoring_user: [
    "list-manager-list",
    "acitivity-list",
    "acitivity-list-edit",
    "settings",
    "list-manager-add",
    "list-manager-import",
    "list-manager-delete",
    "list-manager-edit",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
    "settings-add-user",
  ],
  admin: [
    "list-manager-list",
    "acitivity-list",
    "acitivity-list-edit",
    "list-manager-add",
    "list-manager-import",
    "list-manager-delete",
    "list-manager-edit",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
    "settings",
    "settings-add-user",
    "settings-add-monitoringUser",
    "update-admin-role",
  ],
  list_alert_manager: [
    "list-manager-list",
    "acitivity-list",
    "acitivity-list-edit",
    "list-manager-add",
    "list-manager-import",
    "list-manager-delete",
    "list-manager-edit",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
  ],
  list_manager: [
    "list-manager-list",
    "list-manager-add",
    "list-manager-import",
    "list-manager-delete",
    "list-manager-edit",
  ],
  report_editor: [
    "acitivity-list",
    "acitivity-list-edit",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
  ],
  report_viewer: [
    "acitivity-list",
    "acitivity-potential-incidents",
    "acitivity-confirmed-incidents",
    "acitivity-resolved-incidents",
    "acitivity-archive-incidents",
  ],
};

const homePage = {
  superadmin: '/',
  monitoring_user: '/',
  admin: '/',
  list_manager: '/',
  report_editor: '/activity',
  report_viewer: '/activity',
};

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    activationMessage: "",
    activationStatus: false,
    clientName: "",
    buildVersion: "",
    environment: "",
    passwordStatus: null,
    showServerModal: true,
    csvAccept: localStorage.getItem("csvAccept") ? parseInt(localStorage.getItem("csvAccept")) : 0,
    checkDobStatus: 0,
    opt_authenticate: localStorage.getItem("opt_authenticate") ?? null,
    show_update_password: localStorage.getItem("show_update_password") ?? false
  }),
  getters: {},
  actions: {
    async login(username, password) {
      await axios
        .post(
          `${baseUrl}/login`,
          {
            username,
            password,
          },
          headers
        )
        .then((user) => {
          if (!user.data.error) {
            user.data.permissions = permissions[user.data.role];
            user.data.home_page = homePage[user.data.role];
            this.user = user.data;
            this.passwordStatus = user.data.pass_status;
            this.csvAccept = user.data.csv_acceptance;
            localStorage.setItem('csvAccept', this.csvAccept);
            localStorage.setItem("user", JSON.stringify(user.data));
            console.log(user.data)
            if(user.data?.otp_enabled == 0){
                this.show_update_password = true;
                this.opt_authenticate = true;
                localStorage.setItem('show_update_password',this.show_update_password);
                localStorage.setItem("opt_authenticate", JSON.stringify(this.opt_authenticate));
            }
          }
        }).then(()=>{
          this.verifyActivationKey({ license_key: "" })
        })
        .catch((error) => {
          let message = error.response.data.message?? error.response.data.detail
          notification.notify({
            title: "Error!",
            text: message,
            type: "error",
          });
        });
    },
    updateRoleInStore(newRole) {
      this.user.role = newRole;
      this.user.permissions = permissions[newRole];
      localStorage.setItem('user', JSON.stringify(this.user));
      window.location.reload(); // Reload the site to apply new role restrictions
    },

    async verifyActivationKey(data) {
      try {
        await axios
          .post(`${baseUrl}/verify-license`, JSON.stringify(data), headers)
          .then((res) => {
            if (!res.data.is_valid_key) {
              this.activationStatus = false;
            } else {
              this.activationStatus = true;
              this.clientName = res.data.client_name;
              this.checkDobStatus = res.data.dob_tolerant_mode;
              localStorage.setItem('ClientName', this.clientName);
              this.buildVersion = res.data.app_version;
              localStorage.setItem('BuildVersion', this.buildVersion);
              this.environment = res.data.environment;
              localStorage.setItem('Environment', this.environment);
            }


            const useStore = useUserStore();
            const { logout } = useAuthStore();
            const application_status = response.headers?.get("application-status")
            const responseStatus = application_status;
            if(useStore.responseStatus !== responseStatus){
              useStore.responseStatus = responseStatus;
              if(useStore.responseStatus ===  200){
                logout();
              }
            }
            this.activationMessage = res.data.message;
          });
      } catch (error) {}
    },
    logout() {
      localStorage.clear();
      resetAllPiniaStores();
    },
  },
});
